<!--

  File: TaskInfo.vue

  Defines a component that shows task information to managers.

  Props:
    taskId: The id of the task being edited.

  Events:
    None

-->
<template>
  <div class="columns" style="max-width: 1200px">
    <div class="column">
      <b-field label="Period of Performance">
        <b-input
          v-model="POP"
          placeholder="Enter period of performance"
          readonly
        />
      </b-field>
      <b-field label="Description">
        <b-input
          v-model="task.Description"
          placeholder="Description"
          readonly
        />
      </b-field>
      <b-field label="Customer">
        <b-input v-model="task.customer.Name" placeholder="Customer" readonly />
      </b-field>
    </div>
    <div class="column">
      <b-field label="Funding End">
        <b-input
          v-model="task.LimitEnd"
          placeholder="Enter last funding day"
          readonly
        />
      </b-field>
      <b-field label="Fee">
        <b-input v-model="task.Fee" placeholder="Fee" readonly />
      </b-field>
      <b-field label="Funding Source Type">
        <b-input
          v-model="task.fundingSourceType.Id"
          placeholder="Funding Source Type"
          readonly
        />
      </b-field>
    </div>
    <div class="column">
      <b-field label="Rollup To">
        <div class="box">
          <b-table :show-header="false" :data="task.rollupToArray">
            <template #empty>
              <div class="hero is-info">
                <div class="hero-body">
                  <h1 class="subtitle">This task does not rollup</h1>
                </div>
              </div>
            </template>
            <b-table-column field="AccountingId">
              <template v-slot="props">
                <span style="white-space: nowrap">{{
                  props.row.AccountingId
                }}</span>
              </template>
            </b-table-column>
            <b-table-column field="Id">
              <template v-slot="props">
                <span>{{ props.row.Id }}</span>
              </template>
            </b-table-column>
          </b-table>
        </div>
      </b-field>
      <b-field label="Rollups">
        <div class="box">
          <b-table :show-header="false" :data="task.rollups.edges">
            <template #empty>
              <div class="hero is-info">
                <div class="hero-body">
                  <h1 class="subtitle">This task has no rollups</h1>
                </div>
              </div>
            </template>
            <b-table-column field="node.AccountingId">
              <template v-slot="props">
                <span style="white-space: nowrap">{{
                  props.row.node.AccountingId
                }}</span>
              </template>
            </b-table-column>
            <b-table-column field="node.Id">
              <template v-slot="props">
                <span>{{ props.row.node.Id }}</span>
              </template>
            </b-table-column>
          </b-table>
        </div>
      </b-field>
    </div>
    <b-loading v-model="$apollo.loading" />
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  components: {},
  props: {
    taskId: { type: String },
  },
  apollo: {
    // Vue-Apollo options here
    task: {
      fetchPolicy: "no-cache",
      query: gql`
        query ($id: ID!) {
          task: task(id: $id) {
            id
            PKEY
            TaskPkeyRollup
            FundingSourceTypePkey
            Id
            Description
            AccountingId
            LimitEnd
            Fee
            StartPOP
            EndPOP
            Closed
            fundingSourceType {
              PKEY
              Id
              Abbrev
              Enum
              id
            }
            customer {
              PKEY
              Name
              Description
              id
            }
            rollups {
              edges {
                node {
                  PKEY
                  Id
                  AccountingId
                }
              }
            }
            rollupTo {
              PKEY
              Id
              AccountingId
            }
          }
        }
      `,
      variables() {
        return {
          id: this.taskId,
        };
      },
      skip() {
        return !this.taskId;
      },
      result() {
        if (!this.task.rollupTo) {
          this.task.rollupToArray = [];
          return;
        }
        this.task.rollupToArray = [
          {
            PKEY: this.task.rollupTo.PKEY,
            AccountingId: this.task.rollupTo.AccountingId,
            Id: this.task.rollupTo.Id,
          },
        ];
      },
    },
  },
  data() {
    return {
      task: {
        customer: {},
        fundingSourceType: {},
        rollups: {},
        rollupTo: {},
      },
    };
  },
  computed: {
    POP() {
      if (this.task.StartPOP && this.task.EndPOP) {
        return this.task.StartPOP + " to " + this.task.EndPOP;
      }
      return undefined;
    },
  },
  watch: {},
  methods: {},
};
</script>
